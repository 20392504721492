@import './../../../../scss/theme-bootstrap';

.homepage-formatter {
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  width: 100%;
  &__item {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
    @include breakpoint($large-up) {
      margin-bottom: 40px;
    }
  }
}
